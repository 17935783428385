import axios from 'axios'

const instance = axios.create({
    baseURL: process.env.VUE_APP_NOTIFY_URL,
    headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
    },
})

export default instance
