<template>
    <div>
        <div class="row d-flex justify-content-center">
            <div class="col-md-12 col-sm-12">
                <div class="qr-payment-comp text-center pb-4">
                    <div class="qr-header-comp p-2 text-center mb-3">
                        <img width="30%" src="/img/PromptPayHeader.png">
                    </div>
                    <img v-if="paymentBody.type == 'qr'" class="mt-1" width="25%" src="/img/PromptPay.png">
                    <img v-else-if="paymentBody.type == 'creditcard'" class="mt-1" width="35%"
                        src="/img/visa-mastercard-unionpay.png">
                    <div v-if="countdown > 0">
                        <CSpinner v-if="qrString == ''" variant="grow" color="dark" style="margin: 150px;" />
                        <img v-else class="" :src="generateQRCode(qrString)" width="80%">
                        <h5>บจก. สีลม เทคโนโลยี</h5>
                        <h5>{{ $t('payToAmount') }} {{ convertCurrency(package.amount) }} THB
                        </h5>
                        <hr style="width: 80%;" />
                        <div class="mt-4">
                            <h5>{{ $t('payWithIn') }} {{ formattedCountdown }}</h5>
                            <CAlert v-if="loading" color="info">{{ $t('haveNotPaidYet') }}</CAlert>
                            <CButton @click="checkPaymentStatus()" class="mt-2" color="dark" variant="outline">
                                <CSpinner v-if="loading" class="mb-1" component="span" size="sm" aria-hidden="true" />
                                {{ $t('checkStatus') }}
                            </CButton>
                        </div>
                    </div>
                    <div v-if="countdown <= 0">
                        <div style="padding-top: 50px;">
                            <i style="font-size: 120px;" class="fi fi-rr-time-forward"></i><br>
                            <h4> {{ $t('QRCodeExpired') }}</h4>
                        </div>
                        <h5 class="mt-4">บจก. สีลม เทคโนโลยี</h5>
                        <h5>{{ $t('payToAmount') }} {{ convertCurrency(paymentBody.amount) }} THB
                        </h5>
                        <hr style="width: 80%;" />
                        <div class="mt-4">
                            <CButton @click="reCreateQR()" class="mt-2" color="dark" variant="outline">
                                <CSpinner v-if="loading" class="mb-1" component="span" size="sm" aria-hidden="true" />
                                {{ $t('regenerateQR') }}
                            </CButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import util from '@/util/util'
import local from '@/services/local'

export default {
    props: {
        paymentBody: {
            type: Object,
            default: () => ({
                notation: '',
                ref: '',
                type: 'qr',
                amount: 0.00,
                shop: {
                    objectId: '',
                    id: '',
                    branchName: ''
                }
            }),
        },
    },
    data() {
        return {
            countdown: 600, // 10 minutes in seconds
            formattedCountdown: "10:00", // Initial display value
            loading: false,
            qrString: '',
            package: {
                amount: 0,
            },
            statusCheckInterval: null, // For storing interval reference
        };
    },
    computed: {
        shopObjectId() {
            return localStorage.getItem('shopObjectId')
        }
    },
    mounted() {
        this.startCountdown();
        this.createQRCode();

        // ✅ Start polling handleCheckStatus every 3 seconds
        this.statusCheckInterval = setInterval(() => {
            if (this.qrString && !this.loading) {  // Only check if QR exists and not already loading
                this.handleCheckStatus(false);
            }
        }, 3000);
    },
    beforeDestroy() {
        // ✅ Clear interval when component is destroyed
        clearInterval(this.statusCheckInterval);
    },
    methods: {
        generateQRCode(qrcode) {
            var QRCode = require('qrcode');
            let qrString = '';
            QRCode.toDataURL(qrcode, function (err, url) {
                qrString = url;
            });
            return qrString;
        },

        checkPaymentStatus() {
            this.loading = true;
            this.handleCheckStatus(true);
        },

        reCreateQR() {
            this.startCountdown();
            this.createQRCode();
        },

        async createQRCode(ref) {
            const data = {
                shopObjectId: this.shopObjectId,
                ref: this.paymentBody.ref
            };
            local
                .post('/api/v3.0/systempackage/generateqr', data)
                .then((res) => {
                    this.qrString = res.data?.data?.qrCode || '';
                    this.package = res.data?.data || null;
                })
                .catch((e) => {
                    console.log(e);
                    alert('error');
                });
        },

        startCountdown() {
            this.countdown = 600;
            const timer = setInterval(() => {
                if (this.countdown > 0) {
                    this.countdown--;

                    const minutes = Math.floor(this.countdown / 60);
                    const seconds = this.countdown % 60;
                    this.formattedCountdown = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
                } else {
                    clearInterval(timer);
                }
            }, 1000);
        },

        convertCurrency(amount) {
            return util.convertCurrency(parseFloat(amount));
        },

        // ✅ Polling method for checking payment status
        async handleCheckStatus(isLoading) {
            try {
                if (!this.package || !this.package.orderId) {
                    console.warn('No order ID available for status check.');
                    return;
                }
                this.loading = isLoading;
                const response = await local.get(`/api/v3.0/systempackage/validateqr?orderId=${this.package.orderId}`, {
                    headers: {
                        shopObjectId: this.shopObjectId
                    }
                });
                if (response.data && response.data.error && response.data.error.code === 0) {
                    const paymentStatus = response.data?.data?.txnStatus || response.data?.data?.transaction?.status || ''
                    if (paymentStatus === 'PAID' || paymentStatus === 'success') {
                        this.qrString = '';
                        this.$emit('payment-success');
                        clearInterval(this.statusCheckInterval); // ✅ Stop polling after success
                    }
                } else {
                    console.warn('Payment not completed yet.');
                }
            } catch (error) {
                console.error('Error checking payment status:', error);
                clearInterval(this.statusCheckInterval);
            } finally {
                this.loading = false;
            }
        }
    }
};


</script>

<style>
.qr-payment-comp {
    border: 1px solid #ebebeb;
    width: 100%;
    background-color: white;
    border-radius: 7px;
    aspect-ratio: 1/1.5;
}

.qr-header-comp {
    width: 100%;
    background-color: #133d65;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}
</style>
