<template>
    <div id="invoice-container">
        <div id="invoice" class="invoice-box">
            <!-- Invoice content goes here -->
            <table>
                <tr class="top">
                    <td colspan="2">
                        <table>
                            <tr>
                                <td class="title">
                                    <img src="/img/brand/silom-new-logo.svg" style="width: 100%; max-width: 300px" />
                                </td>
                                <td>
                                    <h2> ใบเสร็จรับเงิน</h2> <br>
                                    เลขที่ : {{ purchaseHistory.orderNo || '' }}<br />
                                    สร้างวันที่ : {{ formattedDate(purchaseHistory.created_at) }}
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr class="information">
                    <td colspan="2">
                        <table>
                            <tr>
                                <td>
                                    บริษัท สีลมเทคโนโลยี จำกัด <br />
                                    1000/66 ลิเบอร์ตี้พลาซ่า ชั้น 3 <br />
                                    ซอยสุขุมวิท 55(ทองหล่อ) <br />
                                    คลองตันเหนือ เขตวัฒนา กรุงเทพฯ 10110<br />
                                    เบอร์โทรศัพท์ 021143042
                                </td>

                            </tr>
                            <tr>
                                <td v-if="customerData !== null">
                                    <h5>ลูกค้า</h5>
                                    คุณ {{ customerData.firstName + ' ' + customerData.lastName }}<br>
                                    ที่อยู่ {{ customerData.address + customerData.subDistrict.name + ' อำเภอ' +
                                        customerData.district.name }}<br />
                                    {{ ' จังหวัด' + customerData.province.name }}<br>
                                    เบอร์โทรศัพท์ {{ customerData.phoneNumber }}
                                </td>
                                <td>

                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr class="heading">
                    <td>รายการ</td>
                    <td>ราคา</td>
                </tr>
                <tr class="item">
                    <td>
                        แพ็กเกจ {{ getPlan(purchaseHistory.package) }} <br>
                    </td>
                    <td>
                        {{ getCurrency(purchaseHistory.price) }} THB<br>
                    </td>
                </tr>
                <tr class="heading">
                    <td>วิธีการชำระเงิน</td>
                    <td>ราคา</td>
                </tr>
                <tr class="details">
                    <td>{{ purchaseHistory.type }}</td>
                    <td>{{ getCurrency(purchaseHistory.txnAmount) }} THB</td>
                </tr>

                <tr class="total">
                    <td>( {{ getThaiBaht() }} )</td>
                    <td>รวมทั้งสิ้น {{ getCurrency(purchaseHistory.price) }} THB</td>
                </tr>
            </table>
            <div class="mt-4">* หมายเหตุ หากต้องการใบกำกับภาษีเต็มรูป
                กรุณาติดต่อเจ้าหน้าที่ภายในวันที่ชำระเงิน
            </div>
        </div>
        <!-- <div>
            {{ purchaseHistory }}
        </div> -->

    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import util from '@/util/util'
import pos from '@/services/local';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";


export default {
    name: 'Invoice',
    props: {
        purchaseHistory: {
            type: Object,
            default: () => ({
                orderNo: '',
                created_at: new Date().toISOString(),
                package: '',
                price: 0,
                txnAmount: 0,
                type: '',
            })
        }
    },
    data() {
        return {
            receiptData: [],
            customerData: null
        };
    },
    computed: {
        ...mapGetters(['shops', 'users'])
    },
    created() {
        this.getShopAddress();
    },
    methods: {
        ...util,

        getShopAddress() {
            const uid = `${localStorage.getItem('uid')}`;
            const shopObjectId = `${localStorage.getItem('shopObjectId')}`;

            let params = {
                shopObjectId: shopObjectId
            };

            pos({
                method: 'get',
                url: '/api/v2.2/' + uid + '/ShopAddress',
                params: params
            }).then((res) => {
                this.customerData = res.data.data[0];
            });
        },

        getCurrency(amount) {
            return util.convertCurrency(amount);
        },

        getThaiBaht() {
            let price = this.purchaseHistory?.price?.toFixed(2) || 0.00
            try {
                return util.ThaiBaht(price);
            } catch (error) {
                return 0
            }

        },

        formattedDate(isoDate) {
            const date = new Date(isoDate);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');

            return `${day}/${month}/${year}, ${hours}:${minutes}:${seconds}`;
        },

        getPlan(plan) {
            switch (plan) {
                case 'multiplePOS':
                    return 'SMART+';
                case 'starter':
                    return 'STARTER';
                case 'starterPlus':
                    return 'STARTER PLUS';
                default:
                    return plan;
            }
        },

        getReceipt() {
            return new Promise((resolve) => {
                setTimeout(() => {
                    const invoice = this.$el.querySelector('#invoice');
                    html2canvas(invoice).then((canvas) => {
                        const imgData = canvas.toDataURL('image/jpeg');
                        const pdf = new jsPDF('p', 'pt', 'a4');
                        const imgWidth = 595.28;
                        const pageHeight = 841.89;
                        const imgHeight = (canvas.height * imgWidth) / canvas.width;
                        let heightLeft = imgHeight;
                        let position = 0;

                        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                        heightLeft -= pageHeight;

                        while (heightLeft > 0) {
                            position = heightLeft - imgHeight;
                            pdf.addPage();
                            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                            heightLeft -= pageHeight;
                        }

                        const pdfBlob = pdf.output('blob');
                        const pdfUrl = URL.createObjectURL(pdfBlob);
                        window.open(pdfUrl);
                        resolve();
                        this.clicked = false;
                    });
                }, 500);
            })


        }
    }
};

</script>

<style scoped>
.invoice-box {
    max-width: 800px;
    margin: auto;
    padding: 30px;

    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    font-size: 16px;
    line-height: 24px;
    font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
    color: #555;
}

.invoice-box table {
    width: 100%;
    line-height: inherit;
    text-align: left;
}

.invoice-box table td {
    padding: 5px;
    vertical-align: top;
}

.invoice-box table tr td:nth-child(2) {
    text-align: right;
}

.invoice-box table tr.top table td {
    padding-bottom: 20px;
}

.invoice-box table tr.top table td.title {
    font-size: 45px;
    line-height: 45px;
    color: #333;
}

.invoice-box table tr.information table td {
    padding-bottom: 40px;
}

.invoice-box table tr.heading td {
    background: #eee;
    border-bottom: 1px solid #ddd;
    font-weight: bold;
}

.invoice-box table tr.details td {
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
}

.invoice-box table tr.item td {
    border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
    border-bottom: none;
}

.invoice-box table tr.total td:nth-child(2) {
    border-top: 2px solid #eee;
    font-weight: bold;
    padding-top: 20px;
}

.invoice-box table tr.total td:nth-child(1) {
    border-top: 2px solid #eee;
    font-weight: bold;
    padding-top: 20px;
}
</style>
