<template>
    <div v-if="!loadingPage">
        <div v-if="!hasConfig" class="row p-0 d-flex align-items-center">
            <div class="col-md-6 p-0">
                <img src="/img/silomAlert/silomAlertAD.JPG" width="100%">
            </div>
            <div class="col-md-6 p-2 input-container-center">
                <div class="text-center mt-4">
                    <h5>{{ $t('enterReferenceCode') }}</h5>
                    <span style="color: gray;">{{ $t('enterReferenceCodeDescription') }}</span>
                    <div class="mb-2 mt-4">
                        <CButton v-for="(channel, index) in channelOptions" @click="showBotQRChannel(index)"
                            class="channel-btn position-relative" :key="'channel' + index">
                            <span v-if="index == 2" class="badge-top-right row">
                                soon</span>
                            <img width="35px" :src="channel.img">
                            <span>{{ channel.title }}</span>
                        </CButton>
                        <div v-if="isCollapsed" class="mt-3 collapse-content">
                            <CAlert color="info" class="p-2 d-flex justify-content-center">
                                <div class="row w-100">
                                    <div class="col-md-8 col-sm-12 text-left pt-2" v-html="botQRManual">
                                    </div>
                                    <div class="col-md-4 col-sm-12">
                                        <img style="border-radius: 10px;" width="100%" :src="botQRImage">
                                    </div>
                                </div>
                            </CAlert>
                        </div>
                    </div>
                    <CAlert v-if="alertBadge" class="mt-3" color="danger">{{ $t('incorrectRefCode') }}
                    </CAlert>
                    <input :id="`otpFormInput2`" maxlength="6" :placeholder="$t('enterSixDigitSilomBot')" v-model="otp"
                        class="form-control center-Placholder mt-4 mb-3">
                    <span @click="goToHelpCenter()" :id="`goToHelp`" style="color: grey;">{{ $t('howToGetCode') }}<span
                            style="text-decoration: underline;cursor: pointer;" class="ml-1">{{
                                $t('goToHelp')
                            }}</span></span>
                    <CButton id="verifyOTPButton2" class="mt-3" style="width: 100%;" @click="verifyOTP()"
                        color="success">
                        <CSpinner v-if="loadingButton" color="white" size="sm" />
                        <b>{{ $t("confirm") }}</b>
                    </CButton>
                </div>
            </div>
        </div>
        <div v-else class="row ">
            <div class="col-md-12 col-sm-12 ">
                <CCard color="white" text-color="dark" class="text-center " body-wrapper>
                    <div>
                        <div class="w-100 text-right m-0 p-0">
                            <CButton id="openOTPModalButton" @click="otpModal = true" color="light">
                                <div class="text-center">
                                    <i class="fi fi-rr-plus mr-2"></i> {{ $t('addNotificationChannel') }}
                                </div>
                            </CButton>
                        </div>
                        <CRow>
                            <CCol md="12" class="text-center mt-4">
                                <h2 class="font-weight-medium text-dark">{{ $t('notifications') }}</h2>
                                <h6 class="text-description">
                                    {{ $t('setNotificationPreferences') }}
                                </h6>
                            </CCol>
                        </CRow>
                        <div class="row mt-3">
                            <div v-for="(info, index) in statistic" class="col-md-3 col-6 p-1 border-separator">
                                <div class="col-top">
                                    <div class="position-relative">
                                        <i @click="showQuotaInfoModal = true" v-if="index == 0"
                                            style="position: absolute;top: -20px;left: -20px;font-size: large; cursor: pointer;"
                                            class="fi fi-rr-info"></i>
                                        <span>{{ info.title }}</span>
                                        <h5>{{ info.value.toLocaleString() }} {{ $t('message') }}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 col-sm-12">
                                <CButton id="openPackageModalButton" class="mt-4 " @click="togglePackageModal()" block
                                    color="light">
                                    <div class="text-center">
                                        <i class="fi fi-rr-coins mr-2"></i> {{ $t('topUpQuota') }}
                                    </div>
                                </CButton>
                            </div>
                            <div class="col-md-6 col-sm-12">
                                <CButton id="openHistoryModalButton" class="mt-4" @click="openHistoryModal()" block
                                    color="light">
                                    <div class="text-center">
                                        <i class="fi fi-rr-time-past mr-2"></i> {{ $t('actiosHistory') }}
                                    </div>
                                </CButton>
                            </div>
                        </div>
                        <!-- <hr /> -->
                        <div class="text-left">
                            <div v-for="platform in platforms" :key="platform.type">
                                <div v-for="(destination, destIndex) in platform.messaging.destinations"
                                    :key="destination.id" class="mt-3 position-relative"
                                    :id="`${platform.type}Index${destIndex}`">
                                    <div v-if="platform.type === 'line'" class="ribbon-wrapper">
                                        <div class="ribbon">
                                            <div class="row justify-content-center">
                                                <i class="fi fi-rr-crown mr-1"></i> Premium
                                            </div>
                                        </div>
                                    </div>
                                    <div style="border: 1px solid #dddddd; border-radius: 10px;" class="row p-4">
                                        <div class="col-md-5 col-sm-12">
                                            <div class="group-card text-center">
                                                <div>
                                                    <template v-if="destination.remoteImagePath">
                                                        <img class="rounded-circle" width="100px"
                                                            :src="destination.remoteImagePath" />
                                                    </template>
                                                    <div class="w-100 d-flex justify-content-center" v-else>
                                                        <div class="rounded-circle"
                                                            style="width: 100px; height: 100px; display: flex; justify-content: center; align-items: center; font-size: 46px; font-weight: bold; background-color: #ddd;">
                                                            {{ getFirstLetter(destination.name) }}
                                                        </div>
                                                    </div>
                                                    <h4 class="mt-4 mb-3">
                                                        <img width="20px" :src="platform.icon" />
                                                        {{ destination.name }}
                                                    </h4>
                                                    <div class="d-flex justify-content-center">
                                                        <CDropdown color="dark">
                                                            <template #toggler>
                                                                <CButton
                                                                    :id="`${platform.type}SelectLanguage${destIndex}`"
                                                                    color="dark" class="square-btn ml-2">
                                                                    <i class="fi fi-rr-earth-americas mr-2"></i> {{
                                                                        destination.language.toUpperCase() }}
                                                                </CButton>
                                                            </template>
                                                            <div class="w-100 text-center">{{ $t('languageSelection') }}
                                                            </div>
                                                            <CDropdownItem class="mt-2"
                                                                v-if="destination.language !== option.key"
                                                                v-for="(option, index) in languegeOptions"
                                                                :key="'langIcon_' + index"
                                                                :id="`${platform.type}DropdownLanguage${destIndex}`"
                                                                @click="updateLanguageByDestinationId(destIndex, platform.type, destination.id, option.key)">
                                                                <img class="mr-2" width="30px" :src="option.img" />
                                                                <span>{{ option.title }}</span>
                                                            </CDropdownItem>
                                                        </CDropdown>
                                                        <CButton
                                                            @click="toggleDisconnectModal(destination.name, destination.id, platform.type)"
                                                            color="danger" class="square-btn ml-2"
                                                            :id="`${platform.type}OpenDisconnectModal${destIndex}`">
                                                            <i class="fi fi-rr-trash-xmark"></i>
                                                        </CButton>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-7 col-sm-12 pt-3">
                                            <div v-for="option in notifyOptions" :key="option.type"
                                                class="d-flex justify-content-between mb-3">
                                                <div>
                                                    <h5 class="d-flex align-items-center">
                                                        {{ $t(option.title) }}
                                                    </h5>
                                                    <span style="color: gray;">
                                                        {{ $t(option.description) }}
                                                    </span>
                                                </div>
                                                <div>
                                                    <CSwitch :checked.sync="destination.configs[option.type]"
                                                        @update:checked="updateConfigByDestinationId(platform.type, destination.id, option.type, destination.configs[option.type])"
                                                        class="ml-2" color="success"
                                                        :id="`${platform.type}NotifySwitch${destIndex}`" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </CCard>
            </div>
            <CModal id="registerForNotificationsModal" :closeOnBackdrop="false" :show.sync="otpModal" centered
                :title="$t('registerForNotifications')" header="false" color="success">
                <div class="text-center w-100">
                    <h5>{{ $t('enterReferenceCode') }}</h5>
                    <span style="color: gray;">{{ $t('enterReferenceCodeDescription') }}</span>
                    <div class="mb-2 mt-4">
                        <CButton v-for="(channel, index) in channelOptions" @click="showBotQRChannel(index)"
                            class="channel-btn position-relative" :key="'channel' + index">
                            <span v-if="index == 2" class="badge-top-right row">
                                soon</span>
                            <img width="35px" :src="channel.img">
                            <span>{{ channel.title }}</span>
                        </CButton>
                    </div>
                    <div v-if="isCollapsed" class="mt-3 collapse-content">
                        <CAlert color="info" class="p-2">
                            <div class="row w-100">
                                <div class="col-md-8 col-sm-12 text-left pt-2" v-html="botQRManual"> </div>
                                <div class="col-md-4 col-sm-12">
                                    <img style="border-radius: 10px;" width="100%" :src="botQRImage">
                                </div>
                            </div>
                        </CAlert>
                    </div>
                    <CAlert v-if="alertBadge" class="mt-3" color="danger">{{ $t('incorrectRefCode') }}</CAlert>
                    <input :id="`otpFormInput2`" maxlength="6" :placeholder="$t('enterSixDigitSilomBot')" v-model="otp"
                        class="form-control center-Placholder mt-4 mb-3">
                    <span @click="goToHelpCenter()" :id="`goToHelp`" style="color: grey;">{{ $t('howToGetCode') }}<span
                            style="text-decoration: underline;cursor: pointer;" class="ml-1">{{
                                $t('goToHelp')
                            }}</span></span>
                </div>
                <template #footer-wrapper>
                    <div class=" row text-center pr-3 pl-3 pb-3 d-flex justify-content-center">
                        <div class="col-md-6 col-sm-12 pt-2">
                            <CButton id="cancelRegisterButton" style="width: 100%;" @click="otpModal = false"
                                color="light">
                                <b>{{ $t("cancel") }}</b>
                            </CButton>
                        </div>
                        <div class="col-md-6 col-sm-12 pt-2">
                            <CButton id="verifyOTPButton2" style="width: 100%;" @click="verifyOTP()" color="success">
                                <CSpinner v-if="loadingButton" color="white" size="sm" />
                                <b>{{ $t("confirm") }}</b>
                            </CButton>
                        </div>
                    </div>
                </template>
            </CModal>
            <CModal :closeOnBackdrop="false" :show.sync="packageModal" centered :title="$t(packageModalTitle)"
                color="success" size="lg" :header="false">
                <div class="text-center">
                    <div v-if="stage == 0" class="row p-3">
                        <div v-for="(packageInfo, index) in packages" class="col-md-4 col-sm-12 p-2">
                            <div class="package-price-card position-relative d-flex flex-column">
                                <span class="badge-top-left">{{ $t(packageInfo.badgeText) }}</span>
                                <div class="flex-grow-1 d-flex flex-column align-items-center text-center p-3">
                                    <h2 class="text-success mt-3 mb-3">{{ $t(packageInfo.title) }}</h2>
                                    <span class="text-muted">{{ $t(packageInfo.description) }}</span>
                                    <h1 class="mt-4">{{ packageInfo.quotas.toLocaleString() }}</h1>
                                    <span class="text-muted">{{ $t('message') }}</span>
                                    <ul class="text-left text-muted mt-3">
                                        <li>
                                            <h6>{{ $t('packagePrice') }} {{ packageInfo.price.toLocaleString() }} {{
                                                $t('baht') }}</h6>
                                        </li>
                                        <li>
                                            <h6>{{ $t('averagePricePerMessage') }} {{ (packageInfo.price /
                                                packageInfo.quotas).toFixed(2) }} {{ $t('baht') }}</h6>
                                        </li>
                                    </ul>
                                </div>
                                <CButton @click="buyMessage(packageInfo, index)" block color="light" class="mt-auto">
                                    {{ $t('buy') }}
                                </CButton>
                            </div>
                        </div>
                    </div>
                    <div v-if="stage == 1" class="row">
                        <div class="col-md-6 col-sm-12 p-1">
                            <AppQRPayment :paymentBody="paymentBody" @payment-success="onPaymentSuccess" />
                        </div>
                        <div class="col-md-6 col-sm-12 p-1">
                            <div class="col-md-12 col-sm-12">
                                <div class="package-price-card position-relative">
                                    <span class="badge-top-left">{{ $t(selectedPackage.badgeText) }}</span>
                                    <div>
                                        <h2 style="color: var(--success);" class="mt-4 mb-4">{{
                                            $t(selectedPackage.title) }}
                                        </h2>
                                        <span style="color: gray;">{{ $t(selectedPackage.description) }}</span>

                                        <h1 class="mt-4">{{ selectedPackage.quotas.toLocaleString() }}</h1>
                                        <span style="color: gray;">{{ $t('message') }}</span>

                                        <ul style="color: gray;" class="text-left mt-4">
                                            <li>
                                                <h6>{{ $t('packagePrice') }} {{ selectedPackage.price.toLocaleString()
                                                    }} {{
                                                        $t('baht') }}</h6>
                                            </li>
                                            <li>
                                                <h6>{{ $t('averagePricePerMessage') }} {{ (selectedPackage.price /
                                                    selectedPackage.quotas).toFixed(2) }} {{ $t('baht') }}</h6>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <!-- เงื่อนไขการชำระเงิน -->
                                <div class="mt-4 p-2 text-left">
                                    <h5 class="mb-3">{{ $t('paymentTerms') }}</h5>
                                    <ul class="payment-terms">
                                        <li>{{ $t('paymentMethods') }}</li>
                                        <li>{{ $t('creditAddedWithin5Min') }}</li>
                                        <li>{{ $t('contactSupportIfNoCredit') }}</li>
                                        <li>{{ $t('noRefundOrChange') }}</li>
                                    </ul>
                                </div>
                                <CButton @click="cancelPayment()" block color="danger">{{ $t('cancelPayment') }}
                                </CButton>
                            </div>
                        </div>
                    </div>
                </div>
                <template #footer-wrapper>
                    <div></div>
                </template>
            </CModal>
            <CModal :show.sync="disconnectModal" centered :title="$t('deleteNotificationChannel')" color="danger"
                header="false">
                <div class="text-center p-3">
                    <h4>{{ $t('deleteNotificationPrompt') }}</h4>
                    <CAlert v-if="alertBadge" class="mt-3" color="danger">{{ $t('unknownError') }}</CAlert>
                    <h5 class="mt-3 mb-3">
                        <img v-if="modalContent.type == 'line'" class="mr-1 mb-1" width="20px"
                            src="/img/line-icon.png" />
                        <img v-if="modalContent.type == 'telegram'" class="mr-1 mb-1" width="20px"
                            src="/img/applicationIcon/telegramIcon.webp" />
                        {{ modalContent.title }}
                    </h5>
                    <span style="color: gray;">{{ $t('deleteNotificationWarning') }}</span>
                </div>
                <template #footer>
                    <div class="w-100 row">
                        <div class="col-md-6 col-sm-12 p-1">
                            <CButton style="width: 100%;" @click="disconnectModal = false" color="light">
                                <b>{{ $t("cancel") }}</b>
                            </CButton>
                        </div>
                        <div class="col-md-6 col-sm-12 p-1">
                            <CButton style="width: 100%;"
                                @click="removeDestination(modalContent.type, modalContent.targetId)" color="danger">
                                <CSpinner v-if="loadingButton" color="white" size="sm" />
                                <b>{{ $t("confirm") }}</b>
                            </CButton>
                        </div>
                    </div>
                </template>
            </CModal>
            <CModal :show.sync="historyModal" centered :title="$t('actiosHistory')" color="success" header="false"
                size="lg">
                <div class="text-center p-3">
                    <DataTable :loading="tableLoading" pagination class="text-dark" :items="getItems"
                        :fields="getColumns" hover border>
                        <template #date="{ item, index }">
                            <td>
                                <span class="text-dark">{{ convertTime(item.date) }}</span>
                            </td>
                        </template>
                        <template #status="{ item, index }">
                            <td>
                                <CBadge class="h6" :color="getColor(item.status)"> {{ $t(getStatusText(item)) }}
                                </CBadge>
                            </td>
                        </template>
                        <template #getReceipt="{ item, index }">
                            <td class="text-center text-dark">
                                <CButton v-if="item.status !== 'success'" variant="outline"
                                    @click="handleCheckStatus(item, index)" color="success" class="pt-2 m-1">
                                    <CSpinner v-if="index == loadingIndexButton" color="dark" size="sm" />
                                    <i v-else class="fi fi-rr-rotate-right"></i>
                                </CButton>
                                <CButton v-else variant="outline" @click="getReceipt(index)" color="primary"
                                    class="pt-2 m-1">
                                    <CSpinner v-if="index == loadingIndexButton" color="dark" size="sm" />
                                    <i v-else class="fi fi-rr-print"></i>
                                </CButton>
                            </td>
                        </template>
                    </DataTable>
                </div>
                <template #footer>
                    <div class="w-100 row">
                        <div class="col-12 p-1">
                            <CButton style="width: 100%;" @click="historyModal = false" color="light">
                                <b>{{ $t("closed") }}</b>
                            </CButton>
                        </div>
                    </div>
                </template>
            </CModal>
            <CModal :show.sync="paymentSuccessModal" centered :title="$t('paymentSuccessTitle')" color="success"
                header="false">
                <div class="text-center p-4">
                    <i class="fi fi-rr-check-circle" style="font-size: 80px; color: var(--success);"></i>
                    <h3 class="mt-3">{{ $t('paymentSuccessful') }}</h3>
                    <p class="text-muted" v-html="$t('paymentSuccessDescription')" />
                </div>
                <template #footer-wrapper>
                    <div class="w-100 text-center">
                    </div>
                </template>
            </CModal>
            <CModal :show.sync="showQuotaInfoModal" centered
                :title="$t('headerNotifyFeature')" color="success" header="false">
                <div class="text-center p-1">
                    <table class="table table-bordered table-striped w-100">
                        <thead>
                            <tr>
                                <th class="text-center">{{ $t('feature') }}</th>
                                <th class="text-center">Telegram (Free)</th>
                                <th class="text-center">LINE (Premium)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(row, index) in comparisonRows" :key="index">
                                <td>{{ row.feature }}</td>
                                <td>{{ row.telegram ? '✅' : '❌' }}</td>
                                <td>{{ row.line ? '✅' : '❌' }}</td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                <template #footer-wrapper>
                    <div class="w-100 text-center pb-2">
                        <small class="text-muted">{{ $t('chooseYourBestPlatform') }}</small>
                    </div>
                </template>
            </CModal>

            <div style="position: fixed; width: 100%;bottom:1000vh">
                <Invoice ref="invoice" class="mb-4" :purchaseHistory="purchaseHistory">
                </Invoice>
            </div>
        </div>
    </div>
    <div v-else style="background-color: white;padding: 10cap;" class="text-center">
        <CSpinner color="success" size="lg" />
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import notify from '@/services/notify'
import local from '@/services/local'
import selectShop from '@/containers/SelectShop.vue'
import AppQRPayment from '@/components/AppQRPayment'
import Invoice from '../template/DynamicReceipt'
import DataTable from '@/containers/DataTable'
import util from '@/util/util'
import "@/assets/styles/silomNotify.css";


export default {
    components: {
        selectShop,
        AppQRPayment,
        DataTable,
        Invoice
    },
    data() {
        return {
            otp: '',
            lineMessaging: null,
            telegramMessaging: null,
            otpModal: false,
            packageModal: false,
            loading: false,
            loadingButton: false,
            loadingPage: false,
            disconnectModal: false,
            modalContent: {
                title: '',
                targetId: '',
                type: ''
            },
            shopMessageInfo: null,
            messageRemaining: 0,
            dailySpend: 0,
            monthlySpend: 0,
            dailyAverage: 0,
            packages: [],
            notifyOptions: [
                { type: 'sales', title: 'salesNotification', description: 'salesNotificationDescription' },
                { type: 'closeShopReport', title: 'closeShopReportNotification', description: 'closeShopReportNotificationDescription' },
                { type: 'cashRound', title: 'cashRoundNotification', description: 'cashRoundNotificationDescription' },
                { type: 'timeAttendance', title: 'timeAttendanceNotification', description: 'timeAttendanceNotificationDescription' },
            ],
            stage: 0,
            selectedPackage: {},
            paymentBody: {},
            selectedChannelOption: 0,
            channelOptions: [
                { title: "Line", img: "https://cdn-icons-png.flaticon.com/512/124/124027.png" },
                { title: "Telegram", img: "https://cdn.pixabay.com/photo/2021/12/27/10/50/telegram-6896827_1280.png" },
                { title: "Discord", img: "https://static.vecteezy.com/system/resources/thumbnails/006/892/625/small/discord-logo-icon-editorial-free-vector.jpg" }
            ],
            alertBadge: false,
            historyModal: false,
            packageHistory: [
                { date: '2024-02-24', name: 'John Doe', status: 'completed', paidData: true },
                { date: '2024-02-23', name: 'Jane Smith', status: 'pending', paidData: false },
                { date: '2024-02-22', name: 'Mike Johnson', status: 'cancelled', paidData: true },
            ],
            transactions: [],
            tableLoading: false,
            loadingIndexButton: null,
            purchaseHistory: {},
            paymentSuccessModal: false,
            isCollapsed: false,
            showQuotaInfoModal: false,
        }
    },

    computed: {
        ...mapGetters(['shops', 'users', 'date', 'permissions']),
        uid() {
            return localStorage.getItem('uid')
        },
        shopObjectId() {
            return localStorage.getItem('shopObjectId')
        },
        hasConfig() {
            if (this.lineMessaging?.destinations?.length > 0 || this.telegramMessaging?.destinations?.length > 0) {
                return true
            } else {
                return false
            }
        },
        statistic() {
            return [
                { title: this.$t('remainingQuota'), value: this.messageRemaining },
                { title: this.$t('monthlyUsedQuota'), value: this.monthlySpend },
                { title: this.$t('dailyUsedQuota'), value: this.dailySpend },
                { title: this.$t('dailyAverageQuota'), value: this.dailyAverage },
            ]
        },
        packageModalTitle() {
            if (this.stage == 0) {
                return "selectPackage"
            } else {
                return "makePayment"
            }
        },
        platforms() {
            return [
                {
                    type: "line",
                    messaging: this.lineMessaging,
                    icon: "/img/line-icon.png",
                },
                {
                    type: "telegram",
                    messaging: this.telegramMessaging,
                    icon: "/img/applicationIcon/telegramIcon.webp",
                },
            ];
        },
        languegeOptions() {
            return [
                { title: 'ภาษาไทย', key: 'th', img: '/img/languages/th_icon.png' },
                { title: 'English', key: 'en', img: '/img/languages/en_icon.png' },
            ]
        },
        getItems() {
            if (this.transactions.length > 0) {
                return this.transactions.map(transaction => ({
                    date: transaction.created_at,
                    name: this.$t(transaction.metadata.name),
                    price: transaction.metadata.price.toFixed(2),
                    status: transaction.status,
                    orderId: transaction.orderId,
                    refCode: transaction.txnNo || '-',
                }));
            } else {
                return []
            }
        },
        getColumns() {
            return [
                { key: 'date', label: this.$t('date'), _classes: 'text-dark' },
                { key: 'name', label: this.$t('name'), _classes: 'text-dark' },
                { key: 'refCode', label: this.$t('refCode'), _classes: 'text-dark' },
                { key: 'price', label: this.$t('price'), _classes: 'text-dark' },
                { key: 'status', label: this.$t('status'), _classes: 'text-dark' },
                { key: 'getReceipt', label: '', _classes: 'text-dark' },
            ];
        },
        botQRImage() {
            const hostname = window.location.hostname;
            const isDev =
                hostname.includes('localhost') ||
                hostname.includes('127.0.0.1') ||
                window.location.href.includes('silompos-dev.web.app');

            const suffix = isDev ? '_dev' : '';

            switch (this.selectedChannelOption) {
                case 0:
                    return `/img/silomAlert/line${suffix}.png`;
                case 1:
                    return `/img/silomAlert/telegram${suffix}.jpg`;
                default:
                    this.isCollapsed = false;
                    return '';
            }
        },
        botQRManual() {
            if (this.selectedChannelOption == 0) {
                return `<ul>
                    <li>${this.$t('createLineGroup')}</li>
                    <li>${this.$t('inviteFriendsAndAddBotLine')}</li>
                    <li>${this.$t('botSendsRegistrationCode')}</li>
                    <li>${this.$t('ifNoCodeTypeRegister')}</li>
                    <li>${this.$t('useCodeForNotifications')}</li>
                </ul>`;
            } else if (this.selectedChannelOption == 1) {
                return `<ul>
                    <li>${this.$t('createTelegramGroup')}</li>
                    <li>${this.$t('inviteFriendsAndAddBotTelegram')}</li>
                    <li>${this.$t('typeRegisterInGroup')}</li>
                    <li>${this.$t('botSendsRegistrationCode')}</li>
                    <li>${this.$t('useCodeForNotifications')}</li>
                </ul>`;
            } else {
                return ``;
            }
        },
        comparisonRows() {
            return [
                { feature: this.$t('notification.basic'), telegram: true, line: true },
                { feature: this.$t('notification.requiresCredits'), telegram: false, line: true },
                { feature: this.$t('notification.futureFeatures'), telegram: false, line: true },
                { feature: this.$t('notification.professionalSuitable'), telegram: false, line: true },
            ]
        }

    },
    created() {
        this.getShopConfig(true)
        this.getSystemPackages()
    },
    methods: {
        verifyOTP() {
            this.loadingButton = true;

            let data = {
                otp: this.otp || '',
                shopObjectId: localStorage.getItem('shopObjectId') || '',
            };

            let config = {
                params: { uid: this.uid }, // Add this.uid as a query parameter
                headers: { shopObjectId: this.shopObjectId }, // Add this.shopObjectId to headers
            };

            notify
                .post('/notify/v1.0/shopconfig/otp/verify', data, config)
                .then((res) => {
                    if (res.data.error.code == 0) {
                        this.getShopConfig();
                        this.otpModal = false;
                        this.otp = '';
                    } else {
                        this.alertBadge = true;
                        setTimeout(() => {
                            this.alertBadge = false;
                        }, 2000);
                    }
                    this.loadingButton = false;
                })
                .catch((e) => {
                    console.log(e);
                    alert('Can not verify OTP');
                    this.loadingButton = false;
                });
        },
        removeDestination(type, destinationId) {
            this.loadingButton = true
            let shopObjectId = localStorage.getItem('shopObjectId') || ''
            let config = {
                params: { uid: this.uid }, // Add this.uid as a query parameter
                headers: { shopObjectId: this.shopObjectId }, // Add this.shopObjectId to headers
            };

            notify
                .delete(`/notify/v1.0/shopconfig/destination/remove?destinationId=${destinationId}&shopObjectId=${shopObjectId}&type=${type}`, config)
                .then((res) => {
                    if (res.data.error.code == 0) {
                        this.disconnectModal = false
                        this.getShopConfig()
                    } else {
                        this.alertBadge = true
                        setTimeout(() => {
                            this.alertBadge = false
                        }, 2000);
                    }
                    this.loadingButton = false
                })
                .catch((e) => {
                    console.log(e)
                    alert('Can not remove destination')
                    this.loadingButton = false
                })
        },
        updateConfigByDestinationId(platform, destinationId, configKey, value) {
            const shopObjectId = this.shopObjectId;
            if (!shopObjectId || !destinationId) {
                alert('Missing shopObjectId or destinationId');
                return;
            }
            let config = {
                params: { uid: this.uid }, // Add this.uid as a query parameter
                headers: { shopObjectId: this.shopObjectId }, // Add this.shopObjectId to headers
            };
            const data = {
                platform,
                shopObjectId,
                destinationId,
                configs: {
                    [configKey]: value, // Update only the specific config key
                },
            };
            this.loading = true; // Optional: Show a loading spinner while the request is in progress
            notify
                .patch('/notify/v1.0/shopconfig/destination/update', data, config)
                .then((res) => {
                    if (res.data.error.code === 0) {
                        setTimeout(() => {
                            this.getShopConfig()
                        }, 3000);

                    } else {
                        console.error('Error updating config:', res.data.error.message);
                        alert('Failed to update config');
                    }
                })
                .catch((err) => {
                    console.error('Error updating config:', err);
                    alert('An error occurred while updating the config');
                })
                .finally(() => {
                    this.loading = false; // Hide the loading spinner
                });
        },
        updateLanguageByDestinationId(index, platform, destinationId, newLanguage) {
            const shopObjectId = this.shopObjectId;
            let config = {
                params: { uid: this.uid }, // Add this.uid as a query parameter
                headers: { shopObjectId: this.shopObjectId }, // Add this.shopObjectId to headers
            };

            if (!shopObjectId || !destinationId || !newLanguage) {
                alert('❌ Missing required fields');
                return;
            }
            const data = {
                platform,
                shopObjectId,
                destinationId,
                language: newLanguage, // Only updating language
            };
            if (platform == 'line') {
                this.lineMessaging.destinations[index].language = newLanguage
            } else if (platform == 'telegram') {
                this.telegramMessaging.destinations[index].language = newLanguage
            }

            this.loading = true;
            notify
                .patch('/notify/v1.0/shopconfig/destination/updateLanguage', data, config)
                .then((res) => {
                    if (res.data.error.code === 0) {
                        this.getShopConfig(); // Refresh shop config
                    } else {
                        console.error('❌ Error updating language:', res.data.error.message);
                        alert(`Failed to update language: ${res.data.error.message}`);
                    }
                })
                .catch((err) => {
                    console.error('❌ Error updating language:', err);
                    alert('An error occurred while updating the language');
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async getShopConfig(pageLoad = false) {
            this.loadingPage = pageLoad
            const uid = this.uid
            let params = {
                shopObjectId: this.shopObjectId,
                uid: uid
            }
            await notify({
                method: 'get',
                url: '/notify/v1.0/shopconfig',
                params: params,
                headers: { shopObjectId: this.shopObjectId }
            }).then((res) => {
                if (res.data.error.code === 0) {
                    this.lineMessaging = res?.data?.data?.line || {}
                    this.telegramMessaging = res?.data?.data?.telegram || {}
                    this.shopMessageInfo = res?.data?.data || {}
                    this.messageRemaining = this.shopMessageInfo?.messageBalance - this.shopMessageInfo?.messageCount || 0
                    this.dailySpend = this.shopMessageInfo?.daily || 0
                    this.monthlySpend = this.shopMessageInfo?.monthly || 0
                    this.dailyAverage = parseInt(this.shopMessageInfo?.dailyAverage) || 0
                }
                this.loadingPage = false
            })
        },
        toggleDisconnectModal(name, id, type) {
            this.modalContent.title = name
            this.modalContent.targetId = id
            this.modalContent.type = type
            this.disconnectModal = true
        },
        togglePackageModal() {
            this.packageModal = true
        },
        buyMessage(packageInfo, index) {
            const shop = this.shops.find((i) => i.objectId === this.shopObjectId)
            this.paymentBody = {
                ref: packageInfo.ref,
                amount: packageInfo.price.toFixed(2),
                shop: {
                    objectId: shop.objectId,
                    id: shop.id,
                    branchName: shop.branchName
                }
            }
            this.selectedPackage = packageInfo
            this.stage = 1
        },
        cancelPayment() {
            this.stage = 0
        },
        convertCurrency(amount) {
            return util.convertCurrency(amount)
        },
        getChannelOptionCalss(index) {
            if (this.selectedChannelOption == index) {
                return 'channel-btn-selected position-relative'
            } else {
                return 'channel-btn position-relative'
            }
        },
        getFirstLetter(name) {
            return name ? name.charAt(0).toUpperCase() : "?"; // Default to "?" if no name is provided
        },
        getTransactions() {
            this.tableLoading = true
            local
                .get(`/api/v3.0/systempackage/transactions/NOTIFY/${this.shopObjectId}`)
                .then((res) => {
                    this.transactions = res.data?.data || []
                    this.tableLoading = false
                })
                .catch((e) => {
                    console.log(e);
                    alert('Can not get Transaction');
                });
        },
        getColor(staus) {
            switch (staus) {
                default:
                    return 'light'
            }
        },
        openHistoryModal() {
            this.getTransactions()
            this.historyModal = true
        },

        getStatusText(item) {
            const createdAt = moment(item.date);
            if (!createdAt.isValid()) {
                return 'Invalid Date';
            }
            const isExpired = moment().diff(createdAt, 'minutes') > 10;
            if (isExpired && item.status !== 'success') {
                return 'qrExpired';
            }
            if (item.status === 'pending' && !isExpired) {
                return 'qrPending';
            }
            return item.status;
        },
        convertTime(time) {
            return moment(time).format('DD/MM/YYYY, HH:mm:ss')
        },
        async handleCheckStatus(item, index) {
            this.loadingIndexButton = index;

            await local.get(`/api/v3.0/systempackage/validateqr?orderId=${item.orderId}`, {
                headers: {
                    shopObjectId: this.shopObjectId // Adding shopObjectId to the headers
                }
            })
                .then((res) => {
                    this.loadingIndexButton = null
                    this.getTransactions()
                    this.getShopConfig()
                })
                .catch((e) => {
                    this.loadingIndexButton = null
                    console.error(e);
                    alert('Error while validating QR');
                });
        },
        async getReceipt(index) {
            this.loadingIndexButton = index;

            const data = this.transactions[index];
            this.purchaseHistory = {
                orderNo: data.txnNo || data.orderId || '',
                created_at: data.created_at || '',
                package: this.$t(data.metadata.name) || '',
                price: data.metadata.price || 0.00,
                txnAmount: data.metadata.price || 0.00,
                type: data.type || ''
            };

            try {
                await this.$refs.invoice.getReceipt(); // ✅ Wait for getReceipt to complete
            } catch (error) {
                console.error('Error generating receipt:', error);
            } finally {
                this.loadingIndexButton = null; // ✅ Reset loading state after completion
            }
        },
        async getSystemPackages() {
            try {
                const res = await local.get(`/api/v3.0/systempackage/bykey?key=NOTIFY`, {
                    headers: {
                        shopObjectId: this.shopObjectId
                    }
                });

                if (res && res.data && res.data.data) {
                    // ✅ Map the response to the desired format
                    const mappedPackages = res.data.data.map(pkg => ({
                        title: pkg.metadata.name || '',
                        badgeColor: pkg.metadata.badgeColor || '',
                        badgeText: pkg.metadata.badgeText || '',
                        quotas: pkg.metadata.quotas || 0,
                        price: pkg.metadata.price || 0,
                        description: pkg.metadata.description || '',
                        ref: pkg.ref || ''
                    }));

                    // ✅ Sort packages by price (ascending)
                    mappedPackages.sort((a, b) => a.price - b.price);

                    this.packages = mappedPackages;
                } else {
                    console.error('Invalid response format:', res);
                    alert('Failed to retrieve system packages.');
                }
            } catch (e) {
                console.error('Error fetching system packages:', e);
                alert('Error while getting system packages');
            }
        },
        onPaymentSuccess() {
            this.stage = 0
            this.packageModal = false
            this.paymentSuccessModal = true
            this.getTransactions()
            this.getShopConfig()
        },
        showBotQRChannel(channelIndex) {
            this.selectedChannelOption = channelIndex
            this.isCollapsed = true
        },
        goToHelpCenter() {
            switch (this.selectedChannelOption) {
                case 0:
                    window.open('https://support.silompos.com/th/article/silom-biz-alert-line-pugyam/?bust=1742897348155', '_blank');
                    break;
                case 1:
                    window.open('https://support.silompos.com/th/article/silom-biz-alert-telegram-ak0243/', '_blank');
                    break;
                default:
                    window.open('https://support.silompos.com/th/article/silom-biz-alert-line-pugyam/?bust=1742897348155', '_blank');
                    break;
            }

        }

    },
}
</script>

<style scoped></style>
